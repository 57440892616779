import { transformSiteSelectorLinks } from '@activebrands/core-web/libs/storyblok/utils/transform-site-selector-links';
import { graphql, useStaticQuery } from 'gatsby';

const useSiteSelectorQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            siteSelectorConfig {
                                backgroundDesktop {
                                    ... on BackgroundBynder {
                                        ...BackgroundBynder
                                    }
                                    ... on BackgroundVideo {
                                        ...BackgroundVideo
                                    }
                                    ... on BackgroundImage {
                                        ...BackgroundImage
                                    }
                                }
                                backgroundMobile {
                                    ... on BackgroundBynder {
                                        ...BackgroundBynder
                                    }
                                    ... on BackgroundVideo {
                                        ...BackgroundVideo
                                    }
                                    ... on BackgroundImage {
                                        ...BackgroundImage
                                    }
                                }
                                staticPageHeading {
                                    component
                                    ...Heading
                                }
                                navigationHeading {
                                    component
                                    ...Heading
                                }
                                paragraph {
                                    component
                                    ...Paragraph
                                }
                                links {
                                    ...SiteSelectorLink
                                }
                                externalHeading {
                                    component
                                    ...Heading
                                }
                                externalParagraph {
                                    component
                                    ...Paragraph
                                }
                                externalLinks {
                                    ...SiteSelectorLinkExternal
                                }
                                seo {
                                    ...Seo
                                }
                                redirectLabel
                            }
                        }
                    }
                }
            }
        `
    );
    const data = application.data.config.siteSelectorConfig || {};

    return {
        desktopBackground: data.backgroundDesktop?.[0],
        mobileBackground: data.backgroundMobile?.[0],
        seo: data.seo,
        siteSelectorExternalHeading: data.externalHeading?.[0]?.text,
        siteSelectorExternalHeadingType:
            data.externalHeading?.[0]?.type === 'default' ? 'span' : data.externalHeading?.[0]?.type,
        siteSelectorExternalLinks: data.externalLinks?.length
            ? transformSiteSelectorLinks(data.externalLinks, 'external')
            : [],
        siteSelectorExternalParagraph: data.externalParagraph?.[0]?.richText,
        siteSelectorPageHeading: data.staticPageHeading?.[0]?.text,
        siteSelectorPageHeadingType:
            data.staticPageHeading?.[0]?.type === 'default' ? 'h1' : data.staticPageHeading?.[0]?.type,
        siteSelectorNavigationHeading: data.navigationHeading?.[0]?.text,
        siteSelectorNavigationHeadingType:
            data.navigationHeading?.[0]?.type === 'default' ? 'span' : data.navigationHeading?.[0]?.type,
        siteSelectorLinks: data.links?.length ? transformSiteSelectorLinks(data.links, 'internal') : [],
        siteSelectorParagraph: data.paragraph?.[0]?.richText,
        siteSelectorRedirectLabel: data?.redirectLabel,
    };
};

export default useSiteSelectorQuery;
